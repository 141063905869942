const trips = {
  moreActions: {
    actions: 'Mais',
    sendTrip: 'Enviar Viagem',
    delete: 'Remover',
    addNote: 'Adicionar observação',
    showOnMap: 'Mostrar no mapa',
    sendMessage: 'Enviar mensagem',
    cancelTrip: 'Cancelar Viagem',
    export: 'Exportar',
    tripReports: 'Relatórios de Viagem',
  },
  tripInfo: {
    formTabs: {
      detailsTab: 'Detalhes',
      historyTab: 'Histórico',
    },
    toast: {
      toastSomethingWasWrong: 'Algo deu errado',
      toastActionEndTrip:
        'Final de viagem disponível quando todos os passageiros forem desembarcados',
    },
    headerBreakDown: 'Detalhamento da viagem',
    driverNotAssigned: 'Motorista não atribuído',
    vehicleNotAssigned: 'Veículo não atribuído',
    noDriver: 'Sem motorista',
    noCar: 'Nenhum Veículo',
    noService: 'Sem Serviço',
    seats: 'Assentos',
    originalStation: 'Estação original:',
    newStation: 'Nova estação:',
    moveStopHeader: 'Você está prestes a mover a parada na viagem:',
    date: 'Data',
    pickupTime: 'Horário de embarque',
    eta: 'ESTIMATIVA',
    passengers: 'Passageiros',
    stops: 'Paradas',
    distance: 'Distância',
    miles: 'ml',
    kilometers: 'km',
    modalEndTrip: {
      body: 'Por favor, marcar todos os passageiros como desembarque',
      header: 'Aviso!',
    },
    tripActions: {
      tripBreakdown: 'Detalhamento da viagem',
      merge: 'Fundir viagem',
      send: 'Enviar viagem',
      note: 'Adicionar nota',
      history: 'Histórico',
      msg: 'Envie uma mensagem ao motorista',
      start: {
        name: 'Início de viagem',
        title: 'Ação disponível para o status Aprovado',
      },
      end: {
        name: 'Fim da viagem',
        title:
          'Final de viagem disponível quando todos os passageiros forem desembarcados',
      },
      cancel: 'Cancelar viagem',
      breakdown: 'Detalhamento da viagem',
    },
    stopActions: {
      queue: 'Adicionar à fila',
    },
    passengerActions: {
      queue: 'Adicionar à fila',
    },
    assignLog: {
      button: 'Atribuir informações',
      closeButton: 'Atribuir registro',
      table: {
        headers: {
          driver: 'Motorista',
          vehicle: 'Veículo',
          score: 'Pontuação',
          flag: 'Bandeira',
          minutes: 'Minutos',
          radius: 'Raio',
          seatCount: 'Contagem de Lugares',
          totalTrips: 'Total de Viagens',
          totalPrice: 'Preço Total',
        },
      },
    },
    breakdown: {
      table: {
        headers: {
          id: 'Identificação',
          planned: 'Tempo planejado',
          date: 'Tempo solicitado',
          passenger: 'Passageiro',
          type: 'Tipo',
          from: 'De',
          to: 'Para',
          vin: 'VIN',
          make: 'Fazer',
          model: 'Modal',
          year: 'Ano',
          weight: 'Peso',
          height: 'Altura',
          width: 'Largura',
          length: 'Duração',
          price: 'Preço',
        },
      },
    },
    tripsInfoPlans: {
      toastStatusChanged: 'Status alterado',
      toastStopRemoved: 'Parada removida',
      toastTripRemoved: '[Viagens] removido com sucesso',
      toastTripCanceled: '[Viagens] Cancelada com sucesso',
      toastTripRemoveError: 'Não foi possível Remover [Viagens]',
      toastClearSuccess:
        '[Viagens] Os motoristas e os veículos foram excluídos com sucesso',
      toastClearError:
        '[Viagens] Os motoristas e os veículos foram excluídos com sucesso',
      toastJobRemoved: 'Reserva removida',
      toastSomethingWasWrong: 'Algo deu errado',
      btnRemoveStop: 'Remover',
      reservationActions: {
        btnPickUp: {
          name: 'Recolhimento',
          title: 'Disponível apenas para status na rota',
        },
        btnDroppoff: {
          name: 'Entregue',
          title: 'Disponível apenas para status na rota',
        },
        btnNoShow: {
          name: 'Não comparência',
          title: 'Disponível apenas para status na rota',
        },
        btnCancel: 'Cancelar reserva',
        btnRemove: 'Remover da viagem',
      },
      plannedTime: 'Tempo planejado: {{time}}',
    },
  },
  table: {
    statuses: {
      new: 'Novo',
      inRoute: 'Em rota',
      sent: 'Enviado',
      approved: 'Aprovado',
      pickingUp: 'Recolhimento',
      done: 'OK',
      canceled: 'Cancelado',
    },
    footer: {
      totalSeats: 'Total de Assentos: {{seats}}',
    },
    filters: {
      students: 'Alunos',
    },
    headers: {
      id: 'Identificação',
      status: 'Status',
      date: 'Data',
      from: {
        templates: {
          default: 'De',
          schoolShuttles: 'De',
          trailers: 'De',
          employees: 'De',
        },
      },
      to: {
        templates: {
          default: 'Para',
          schoolShuttles: 'Para',
          trailers: 'Para',
          employees: 'Para',
        },
      },
      seats: {
        templates: {
          default: 'Assentos',
          schoolShuttles: 'Assentos',
          trailers: 'Assentos',
          employees: 'Assentos',
        },
      },
      profile: 'Perfil',
      stops: 'Paradas',
      distance: 'Distância',
      duration: 'Duração',
      service: 'Serviço',
      supplier: 'Fornecedor',
      driver: 'Motorista',
      car: {
        templates: {
          default: 'Veículo',
          schoolShuttles: 'Veículo',
          trailers: 'Trailer',
          employees: 'Veículo',
        },
      },
      actions: 'Ações',
      attr: 'Atributo',
      externalId: 'Identificador Externo',
      waitUntil: 'Aguarde até',
      line: 'Linha',
      passenger: {
        templates: {
          default: 'Passageiro',
          schoolShuttles: 'Aluno',
          trailers: 'Passageiro',
          employees: 'Passageiro',
        },
      },
    },
    icons: {
      shared: 'Viagem Compartilhada',
      preAssigen: 'Atribuição manual',
    },
    cells: {
      id: {
        delay:
          'A chegada a {{nextStop}} está afetada por {{delayTime}} minutos.',
      },
      address: {
        unknown: 'Desconhecido',
      },
      car: {
        templates:{
          default: 'Nenhum Veículo',
          schoolShuttles: 'Nenhum Veículo',
          trailers: 'Nenhum Veículo',
        },
      },
      driver: {
        none: 'Sem motorista',
      },
      supplier: {
        none: 'Sem Fornecedor',
      },
    },
    actions: {
      dropPassenger: {
        modal: {
          part1: 'Mover',
          part2: 'Reserva de viagem',
          part3: 'viajar',
        },
      },
      autoAssign: {
        modal: {
          header: 'Motoristas e veículos atribuem status',
          body: 'Total de Motoristas: {{carsUsed}}\nTotal de Viagens: {{trips}} - Atribuídas: {{match}}, Não Atribuídas: {{noMatch}}',
        },
        errorModal: {
          header: 'Erro',
          body: 'Todas as viagens selecionadas já foram atribuídas. Para reatribuir motoristas, primeiramente apague a atribuição atual.',
        },
      },
      deleteMultipleModal: {
        header: 'Excluir viagens',
        body: 'Você está prestes a excluir {{num}} viagens.',
      },
      deleteModal: {
        body: 'Está prestes a eliminar {{num}} viagem.',
      },
      cancelModal: {
        body: 'Está prestes a cancelar {{num}} viagem.',
      },
      cancelMultipleModal: {
        header: 'Cancelar viagens',
        body: 'Você está prestes a cancelar {{num}} viagens.',
      },
      assign: {
        assign: 'Atribuir',
        clearDriversVehicle: 'Limpar Motoristas/Veículos',
        advancedAssign: 'Atribuição avançada',
        modalHeader: 'Atribuir viagens',
        youAreAboutToAssign:
          'Você está prestes a atribuir {{numberOfTrips}} viagens.',
      },
      cancelReservation: 'Você está prestes a cancelar esta reserva',
      report: {
        header: 'Resumo da Linha',
        body: {
          totalTrips: 'Viagens',
          totalCost: 'Custo',
          totalPrice: 'Preço',
          totalJobs: 'Reservas',
          totalDuration: 'Duração',
          totalDistance: 'Distância',
          totalAssigned: 'Atribuído',
          totalUnassigned: 'Não atribuído',
          totalDrivers: 'Motoristas',
          averageJobs: 'Média Reservas',
          averageDistance: 'Média Distância',
          averageDuration: 'Média Duração',
        },
      },
    },
  },
};

export default trips;
