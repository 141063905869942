export type ISite = {
  _id?: string;
  name: string;
  operation?: OperationTypes;
};

export enum OperationTypes {
  Default = 'default',
  School = 'echool',
  Employees = 'employees',
}
