const jobs = {
  toast: {
    error: {
      existsPassengerToast: {
        body: 'O e-mail do passageiro já está sendo utilizado',
      },
      create: {
        badResponse: 'Ocorreu um erro ao criar a reserva',
        missingParameters:
          'Por favor, vá até as configurações do sistema e preencha os parâmetros que faltam',
      },
      edit: {
        badResponse: 'Ocorreu um erro aquando da atualização da reserva',
      },
      find: {
        badResponse: 'Não foi possível encontrar serviços de transporte',
      },
    },
    success: {
      create: {
        default: 'A reaervação foi criada com sucesso',
      },
      edit: {
        default: 'A reaervação foi atualizada com sucesso',
      },
    },
  },
  create: {
    confirmButtonText: 'Reservar',
    confirmationModal: {
      header: 'Confirmar reserva',
      subtitle:
        'Por Favor, Leia o Roteiro Abaixo - Palavra por palavra - Não tenha pressa!!!',
      content:
        'Temos {{service}} reserva para {{seats}} pessoa\nEm {{date}} de {{from}} para {{to}}\n\nIsso está correto? Ótimo!\n\nO preço é de ${{price}}\nObrigado por ligar para {{company}}',
      table: {
        headers: {
          details: 'Detalhes da Viagem',
          fare: 'tarifa',
        },
        fields: {
          details: {
            pickAdrs: 'Morada de Partida',
            dropAdrs: 'Endereço de depósito',
            pickTime: 'Horário de embarque',
            seats: 'Adultos',
          },
          fare: {
            service: 'Serviço',
          },
        },
      },
      bottomLine: 'Preço Total',
    },
    duplicateJobsModal: {
      body: 'Observe que existem outras reservas com exatamente os mesmos detalhes. Você deseja continuar?',
      btnConfirm: 'Sim',
      btnClose: 'Não',
    },
    blacklistModal: {
      body: 'O número do cartão inserido é inválido. Por favor, digite um número válido.',
      btnConfirm: 'Confirmar',
    },
  },
  payments: {
    table: {
      headers: {
        createdBy: 'Criado',
        email: 'E-mail do usuário originador',
        amount: 'Quantidade',
        type: 'Tipo',
        ccMasked: 'CC Mascarado',
        status: 'Status',
        refNum: 'Número de referência',
      },
    },
    paymentsDetails: {
      title: 'Detalhes',
      status: {
        charged: 'Carregado:',
        pending: 'Pendente:',
        declined: 'Rejeitado:',
        variance: 'Variação:',
      },
    },
    addPaymentForm: {
      title: 'Adicione o pagamento',
      fields: {
        amount: 'Quantidade',
        paymentType: 'Tipo de pagamento',
        cardNum: 'Número do crédito',
        cardExp: 'Expira',
        cvv: 'CVV',
        paymentOptions: {
          select: 'Selecione',
          credit: 'Crédito',
          directBill: 'Fatura direta',
          card: 'Cartões',
          expDate: 'Data de expiração',
        },
      },
      validations: {
        price: 'O preço deve ser maior que zero',
      },
      confirmPaymentBtn: 'Confirmar pagamento',
      modalSuccess: {
        header: 'sucesso!',
        body: 'Pagamento concluído com sucesso!',
      },
      modalError: {
        header: 'Ocorreu um erro!',
        body: 'Falha no pagamento. Por favor, tente novamente',
      },
    },
  },
  update: {
    confirmButtonText: 'Atualizar',
    formTabs: {
      summary: 'Resumo',
      edit: 'Editar',
      payments: 'Pagamentos',
      notes: 'Observações',
      history: 'Histórico',
    },
    summary: {
      driver: 'Motorista',
      vehicle: 'Veículo',
      tripId: 'Identificação de viagem:',
      tripStatus: 'Status de Viagem',
      RPTime: 'Horário de embarque solicitado',
      MPTime: 'Hora de recolha Mooverex',
      PETA: 'ESTIMATIVA de Partida',
      PR: 'Relatado de Coleta (Motorista)',
      DR: 'Desembarque de Motorista Relatado (Motorista)',
      empty: 'Não disponível',
      notAssigned: 'Não atribuído',
      notRouted: 'Não roteado',
      attributes: 'Atributos',
    },
    jobNotSupported: 'O Tipo de Reserva não é suportado',
  },
  setShifts: {
    select: 'Selecione',
    form: {
      information: {
        passengers: {
          header: 'Selecionar passageiros',
          fields: {
            passengers: 'Passageiros',
          },
        },
        details: {
          header: 'Detalhes do Turno',
          fields: {
            direction: {
              label: 'Direção',
              to: 'Para',
              from: 'De',
              both: 'Ambos',
            },
            shift: 'Turno',
            from: 'De',
            to: 'Para',
            fromDate: 'A partir da Data',
            toDate: 'Até à data',
            weekDays: 'Dias da Semana',
            fromHour: 'De hora em hora',
            toHour: 'Para a hora',
          },
        },
      },
    },
  },
  routeSummary: {
    reservations: 'Reservas',
    trips: 'Viagens',
    assigned: 'Atribuir',
    unassigned: 'Não atribuído',
    drivers: 'Motoristas',
    autoAssigned: 'Atribuido automaticamente',
    preAssigned: 'Pré-atribuído',
    vehicles: 'Veículos disponíveis',
    vehiclesUsed: 'Veículos Usados',
    routingInsights: 'Analítica de Rota',
    totalTripDistance: 'Distância total da viagem',
    totalTripDuration: 'Duração total da viagem',
    assignmentInsights: 'Analítica da tarefa',
    avgDistance: 'Média Distância',
    avgDistancePickup: 'Média Distância até o embarque',
    avgDuration: 'Média Duração',
    avgDurationPickup: 'Média Duração até à recolha',
    avgPrice: 'Média Preço',
    avgPassengers: 'Média Passageiros',
    waitAverage: 'Média de Espera',
    routingProcessTime: 'Tempo do Processo de Encaminhamento',
    assignmentProcessTime: 'Atribuição de tempo do processo',
  },
  headerButtons: {
    create: {
      new: 'Novo',
      quick: 'Rápido',
      setShifts: 'Definir turnos',
      shuttle: 'Transporte',
    },
    route: {
      route: 'Rota',
      routeAndAssign: 'Linha e Atribuição',
    },
    moreActions: {
      button: 'Mais',
      options: {
        group: {
          label: 'Grupos',
          modal: {
            header: 'Reservas de grupos',
            body: 'Você está prestes a agrupar {{jobs}} reservas',
          },
        },
        ungroup: {
          label: 'Desagrupar',
          modal: {
            header: 'Desagrupar reservas',
            body: 'Você está prestes a desagrupar {{jobs}} reservas',
          },
        },
        cancel: {
          label: 'Cancelar',
          modalSuccess: {
            header: 'Cancelar reservas',
            body: 'Por favor, insira um motivo para o cancelamento',
          },
        },
        delete: {
          label: 'Remover',
          modalSuccess: {
            header: 'Excluir Trabalhos',
          },
        },
        export: {
          label: 'Exportar',
        },
        releaseRouting: {
          label: 'Lançar Rota',
        },
      },
    },
  },
  advancedRoutingModal: {
    all: 'Tudo',
    RouteBodyText: 'Está prestes a fazer a rota {{num}} reservas.',
    RouteAssignBodyText:
      'Você está prestes a definir e atribuir {{num}} reservas.',
    advancedSettings: 'Avançado',
    noJoin: 'Sem associação',
    joinOptions: {
      noJoin: 'Sem associação',
      tryJoin: 'Tentar aderir',
      onlyJoin: 'Apenas participação',
    },
    clusterSettings: 'Configurações de Grupo',
    assignSettings: 'Atribuir configurações',
    routeJobs: 'Reserva de Linha',
    routeAssignJobs: 'Reservas de Linha e de Atribuição',
    routeAndAssignJobs: 'Encaminhe e atribua tarefas',
    advancedRoutingSettings: 'Configurações avançadas de roteamento',
    mainParameters: 'Parâmetros principais',
    ratio: 'Proporção',
    ratioInfo:
      '1.5 significa que a duração de uma pessoa sentada no veículo não irá exceder 150% do tempo que ela deveria ter gasto conduzindo sozinho.',
    pax: 'Máximo de passageiros',
    paxInfo: 'Número máximo de lugares por viagem',
    maxDuration: 'Duração máxima',
    maxDurationInfo: 'Duração máxima de uma viagem',
    maxStops: 'Paradas máximas',
    maxStopsInfo: 'Máximo de paradas em uma viagem',
    pickSeconds: 'Horário de embarque',
    pickSecondsInfo:
      'Tempo de embarque calculado do trabalho. a duração total da viagem será estendida. Os horários serão adicionados à matriz espera para esta parada.',
    dropSeconds: 'Horário de Recolhimento',
    dropSecondsInfo:
      'Tempo de entrega calculado do trabalho. a duração total da viagem será estendida. Os horários serão adicionados à matriz espera para esta parada.',
    pickGapAfter: 'Atraso máximo',
    pickGapAfterInfo:
      'O horário de recolha de um trabalho não será superior a 30 minutos do horário de recolha de outro trabalho.',
    pickGapBefore: 'Atraso máximo',
    pickGapBeforeInfo:
      'O horário de recolha de um trabalho não precederá o horário de recolha de outro trabalho em mais de 30 minutos.',
    airKMPH: 'Aéreo KMPH',
    airKMPHInfo: 'Entre em contato com o suporte',
    allowLate: 'Permitir tarde',
    allowLateInfo: 'Entre em contato com o suporte',
    maxPrice: 'Preço Máximo',
    maxPriceInfo: 'Entre em contato com o suporte',
    maxTrips: 'Máximo de viagens',
    maxTripsInfo: 'Suporte ao máximo de viagens',
    shiftMaxMinutes: 'Máximo de Minutos do Turno',
    shiftMaxMinutesInfo: 'Entre em contato com o suporte',
    form: {
      validation: {
        mustBeHigher: 'Deve ser {{val}} ou superior',
      },
    },
  },
  clusters: {
    clusterRadius: 'Raio do conjunto',
    clusterSnapAddress: 'Endereço do Snap ao Grupo',
    clusterDirection: 'Direção do agrupamento',
    snapToBusStop: 'Ajustar à Parada de ônibus',
    both: 'Ambos',
    to: 'Para',
    from: 'De',
  },
  forms: {
    roundTrip: 'Viagem de ida e volta',
    oneWay: 'Só ida',
    information: {
      details: {
        header: 'Detalhes do perfil',
        fields: {
          site: 'Local',
          profile: 'Perfil',
        },
      },
      jobDetails: {
        flightTypes: {
          international: 'Internacional',
          domestic: 'Doméstico',
          customerRequest: 'solicitação do cliente',
        },
        fareBreakdown: 'Repartição de tarifa',
      },
      linkedPassengers: {
        header: 'Passageiros Conectados',
        searchPlaceholder: {
          templates: {
            default: 'Pesquisar passageiros',
            schoolShuttles: 'Pesquisar alunos',
            employees: 'Buscar Empregados',
            trailers: 'Pesquisar passageiros',
          },
        },
      },
      reservationDetails: {
        header: 'Detalhes da reserva',
        fields: {
          from: 'De',
          to: 'Para',
          terminal: 'Terminal',
          seats: 'Assentos',
          checkedBags: 'Malas despachadas',
          switchADA: 'ADA',
          wheelchair: 'Cadeira de rodas',
          adults: 'Adultos',
          infants: 'Bebês',
          carryon: 'Continuar',
        },
        clearBtn: 'Limpar',
      },
      flightDetails: {
        header: 'Detalhes do voo',
        flightTimeAndTime: 'Data e Hora do Voo',
        flightType: 'Tipo de Voo',
        flightAirline: 'Linha aérea',
        flightNumber: 'Número do voo',
        flightTerminal: 'Terminal',
      },
      caregiverInformation: {
        header: 'Informações para Cuidadores',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          address: 'Endereço',
          phone: 'Telefone',
        },
      },
      serviceAndTime: {
        header: 'Serviço e Horário',
        fields: {
          scheduleBy: 'Planejamento por',
          dateTime: 'Data e Hora',
          service: 'Serviço',
          switchRecurring: 'Recorrente',
          repeatEvery: 'Repetir a Cada',
          ends: 'Fim',
          never: 'Nunca',
          on: 'Ligado',
          after: 'Depois',
          occurrences: 'Ocorrências',
        },
        buttons: {
          now: 'Agora',
        },
      },
      personalInfo: {
        header: 'Informações pessoais',
        fields: {
          email: 'E-mail',
          fullName: 'Nome completo',
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          phone: 'Telefone',
          address: 'Endereço',
          specialIdentifier: 'Identificador Especial',
        },
      },
      billingInfo: {
        sameAsPersonalInformation: 'O mesmo que informação pessoal',
        header: 'Informações de faturamento',
        fields: {
          country: 'País',
          state: 'Estado',
          zip: 'CEP',
        },
      },
      payments: {
        header: 'Pagamentos',
        fields: {
          price: 'Preço',
          paymentSelect: {
            header: 'Tipo de pagamento',
            options: {
              credit: 'Crédito',
              directBill: 'Fatura direta',
            },
          },
          cardNum: 'Número do crédito',
          cardExp: 'Expira',
          cvv: 'CVV',
        },
      },
      additionalFields: {
        header: 'Campos adicionais',
        fields: {
          driver: 'Motorista',
          car: 'Carro',
          waitMinutes: 'Minutos de espera',
        },
      },
      notes: {
        header: 'Observações',
      },
    },
    validations: {
      unit: {
        required: 'O perfil é obrigatório',
      },
      amount: {
        required: 'O valor é obrigatório',
      },
      from: {
        required: 'De é obrigatório',
      },
      direction: {
        required: 'A direção é obrigatória',
      },
      to: {
        required: 'Para é requerido',
      },
      type: {
        required: 'Necessário',
      },
      email: {
        message: 'Insira um e-mail válido',
        required: 'E-mail é obrigatório',
      },
      firstName: {
        conditions: {
          min: 'O Nome deve ter no mínimo 2 caracteres',
          max: 'O Nome deve ter no máximo 30 caracteres',
        },
        required: 'Primeiro Nome é obrigatório',
      },
      lastName: {
        conditions: {
          min: 'O Sobrenome deve ter no mínimo 2 caracteres',
          max: 'O sobrenome deve ter no máximo 30 caracteres',
        },
        required: 'Sobrenome é obrigatório',
      },
      phone: {
        message: 'O número de telefone não é válido',
        required: 'O número de telefone é obrigatório',
      },
      price: {
        message: 'O preço deve ser um número',
        required: 'O preço é obrigatório',
      },
      flightTerminal: {
        required: 'Terminal de voo é necessário',
      },
      adults: {
        required: 'Adultos é necessário',
      },
      seats: {
        required: 'Lugares é necessário',
      },
      infants: {
        required: 'É necessário Lactente',
      },
      carryon: {
        required: 'É Requerimento de Viagem',
      },
      paymentType: {
        required: 'O Tipo de pagamento é obrigatório',
      },
      cardNum: {
        required: 'O Número do cartão é obrigatório',
      },
      cardExp: {
        required: 'É obrigatória a data de expiração do cartão',
      },
      cvv: {
        required: 'O CVV é necessário',
      },
      profile: {
        required: 'O perfil é obrigatório',
      },
      fare: {
        required: 'A tarifa é obrigatória',
      },
    },
  },
  table: {
    footer: {
      totalSeats: 'Total de Assentos: {{seats}}',
    },
    filters: {
      students: 'Alunos',
    },
    headers: {
      externalID: 'Identificador Externo',
      returnTrip: 'Viagem de ida e volta',
      legA: 'Percurso A',
      legB: 'Percurso B',
      driver: 'Motorista',
      passenger: {
        templates: {
          default: 'Passageiro',
          schoolShuttles: 'Aluno',
          trailers: 'Passageiro',
          employees: 'Passageiro',
        },
      },
      from: {
        templates:{
          default: 'From',
          schoolShuttles: 'From',
          trailers: 'Pickup Location',
          employees:'From'
        },        
      },
      to: {
        templates:{
          default: 'To',
          schoolShuttles: 'To',
          trailers: 'Delivery Location',
          employees: 'To'
        },
      },
      seats: {
        templates: {
          default: 'Seats',
          schoolShuttles: 'Seats',
          trailers: 'Item Quantity',
        }
      },
      vehicle: {
        templates: {
          default: 'Vehicl',
          schoolShuttles: 'Vehicle',
          trailers: 'Trailer',
        }
      },
    },
    statuses: {
      draft: 'Calado',
      new: 'Novo',
      onHold: 'Em espera',
      waiting: 'Aguardando',
      canceled: 'Cancelado',
      routing: 'Encaminhamento',
      routed: 'Encaminhado',
      picked: {
        templates: {
          default: 'Escolhido',
          schoolShuttles: 'Escolhido',
          trailers: 'Escolhido',
        }
      },
      dropped:  {
        templates: {
          default: 'Eliminado',
          schoolShuttles: 'Eliminado',
          trailers: 'Eliminado',
        }
      },
      noShow: {
        templates: {
          default: 'Não comparência',
          schoolShuttles: 'Não comparência',
          trailers: 'Failed Delivery',
        }
      },
    },
    dateType: {
      pick: 'Escolha',
      drop: 'Soltar',
    },
    icons: {
      recuring: 'Reserva recorrente',
    },
    tooltips: {
      group: 'Grupos',
      similar: 'Mostrar Duplicações',
    },
  },
  assignVehiclesDrivers: {
    routeSummaryModal: {
      header: 'Resumo da Linha',
    },
  },
  checkRouteStatus: {
    routeSummaryModal: {
      header: 'Resumo da Linha',
    },
  },
  shuttleReservation: {
    create: {
      confirmButtonText: 'Reservar',
    },
    limitationModal: {
      header: 'Limitação de Reserva',
      body: 'Você está tentando fazer uma reserva dentro do tempo limite de reserva, deseja continuar?',
    },
    form: {
      information: {
        recurring: {
          header: 'Recorrente',
        },
        personalInformation: {
          header: 'Detalhes pessoais',
          seats: '- Assento 1',
          fields: {
            firstName: 'Primeira Nome',
            lastName: 'Sobrenome',
            email: 'E-mail',
            phone: 'Telefone',
          },
        },
        passengers: {
          header: 'Dados Pessoais -',
          seats: 'Assento',
        },
        paymentOption: {
          header: 'Opções de pagamento',
          fields: {
            paymentType: 'Tipo de pagamento',
            cardNum: 'Número do cartão',
            cardExp: 'Expira',
            cvv: 'CVV',
            rate: 'Taxa',
            amount: 'Quantidade',
            fare: 'tarifa',
          },
          paymentTypes: {
            free: 'Livre',
            opusCard: 'Cartão OPUS',
            cashOnBoard: 'Dinheiro a Bordo',
            correspondance: 'Correspondência',
            opusCardMensuel: 'Carte OPUS - Mensuel',
            opusCardPassage: 'OPUS Carte - Passagem',
            cpoPassageSTL: 'Carte a puce occasionnelle (CPO-STL) - Passage',
            cpoPassageEXO: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
          },
          rateTypes: {
            ordinary: 'Valor em Numerário',
            freeZeroToFive: 'Livre (0-5 Anos)',
            freeSixToEleven: 'Livre (6-11 Anos)',
            freeZeroToEleven: 'Gratuit (0-11 Ans)',
          },
        },
        bookingDetails: {
          header: 'Detalhes da reserva',
          fields: {
            supplier: 'Fornecedor',
            date: 'Data',
            hour: 'Hora',
            area: 'Área',
            direction: 'Direção',
            operationHours: 'Horário de funcionamento',
            line: 'Transporte',
            pickup: 'Recolhimento',
            dropoff: 'Desistência',
            seats: 'Assentos',
          },
        },
      },
      validations: {
        drivers: {
          required: 'Motoristas é necessário',
        },
        area: {
          required: 'Área é obrigatória',
        },
        direction: {
          required: 'A direção é obrigatória',
        },
        unit: {
          required: 'Requer-se a unidade',
        },
        supplier: {
          required: 'Requer-se fornecedor',
        },
        driver: {
          required: 'Requer-se motorista',
        },
        start: {
          required: 'A Data de Início é obrigatória',
        },
        end: {
          required: 'A Data de término é obrigatória',
        },
        firstName: {
          required: 'Primeiro Nome é obrigatório',
        },
        lastName: {
          required: 'Sobrenome é obrigatório',
        },
        email: {
          required: 'E-mail é obrigatório',
        },
        phone: {
          required: 'O número de telefone é obrigatório',
        },
        date: {
          required: 'A data é obrigatória',
        },
        line: {
          required: 'É necessário transporte',
        },
        hour: {
          required: 'É requerido uma hora',
        },
        from: {
          required: 'De é obrigatório',
        },
        fromLat: {
          required: 'É obrigatório',
        },
        fromLon: {
          required: 'De é obrigatório',
        },
        to: {
          required: 'Para é requerido',
        },
        toLat: {
          required: 'Para a latitude é necessário',
        },
        toLon: {
          required: 'Para a latitude é necessário',
        },
        seats: {
          required: 'Lugares é necessário',
          conditions: {
            min: 'Os lugares devem ser maiores que {{min}}',
          },
        },
        paymentOptions: {
          required: 'As opções de pagamento são obrigatórias',
          paymentType: {
            required: 'O Tipo de pagamento é obrigatório',
          },
          data: {
            required: 'Os dados de pagamento são obrigatórios',
            opusCard: {
              minLength: 'Deve ter exatos 12 caracteres',
            },
          },
        },
      },
    },
  },
};

export default jobs;
